import React from 'react';
import {list} from "../data/style";

export const Answer = (props) => {
    const { answers, answerCurrent, chooseAnswer } = props;
    let jsElement = [];
    answers && answers.map( (answer, idx) => {
        const activeAnswer = answerCurrent === idx ? "active" : "noActive";
        const element = <li className={`quiz-nadlan-question ${activeAnswer}`} key={idx} onClick={() => chooseAnswer(idx)}>
            <div>{answer}</div> </li>;
       return jsElement.push(element);
    });
    
    return (<ul style={list}>{jsElement}</ul>);
}