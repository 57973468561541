import React from 'react';
import './App.css';
import Quiz from './components/Quiz';

function App() {
  return (
    <div className="container-fluid wrapperNadlan">
      <div className="">
        <div className="quiz-nadlan quizBadlan">
          <Quiz/>
        </div>
      </div>
    </div>
  );
}

export default App;
