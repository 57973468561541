export const questions = [
    // 1
    {
        "question": "בן כמה המשקיע?",
        "answers": [
            "20 - 40", 
            "41 - 60", 
            "61 - 70", 
            "70+"
        ]
    },
    // 2
    {
        "question": "כמה הון עצמי אתה כמשקיע מתכנן להביא לעסקה?",
        "answers": [
            "100,000 - 500,000 ₪", 
            "501,000 - 1,000,000 ₪", 
            "1,101,000 - 5,000,000 ₪", 
            "5,001,000 - 20,000,000 ₪",
            "20,001,000+ ₪"
        ]
    },
    // 3
    {
        "question": "כמה מימון אתה כמשקיע מתכנן לקחת?",
        "answers": [
            "0%", 
            "30%", 
            "50%", 
            "80% - 70",
            "100% - 90"
        ]
    },
    // 4
    {
        "question": "באיזה אופן ברצונך לרכוש את המשרדים?",
        "answers": [
            "כשכיר", 
            "כעצמאי", 
            'כחברה בע״מ', 
            "אחרת..."
        ]
    },
    // 5
    {
        "question": "מהי המטרה של ההשקעה?",
        "answers": [
            "לייצר הכנסה פסיבית מיידית כתוספת להכנסה של היום או לפנסיה", 
            "לייצר הכנסה עתידית כהכנה לפנסיה לזמן הפרישה", 
            "להגדיל הון עצמי (מכירת FLIP)", 
            "לשמור על ההון ואולי להגדילו"
        ]
    },
    // 6
    {
        "question": "איפה החלטת לרכוש משרד?",
        "answers": [
            `ת"א`, 
            `מרכז גוש דן (לא ת"א) – ר"ג, גבעתיים, בני ברק ועוד`, 
            `הפריפריה הקרובה – פ"ת, חולון, ראשון לציון, רחובות, מודיעין ועוד`, 
            "הפריפריה הרחוקה – באר שבע, חדרה, אשקלון, אשדוד",
            `חו"ל`
        ]
    },
    // 7
    {
        "question": "האם בחרת לרכוש משרד במתחם עסקים או במקום אחר?",
        "answers": [
            `בחרתי מתחם גדול וידוע כמו הבורסה, סיטי ת"א או מתחם BBC`, 
            "בחרתי במתחם חדש שמתכננים בעתיד", 
            "אני מתכנן לרכוש בבניין יחיד, חדש ויפה (לא במתחם עסקים גדול)", 
            "אני מתכנן לרכוש בבניין לא חדש ופשוט (20 שנה+) במתחם או מחוץ למתחם עסקים"
        ]
    },
    // 8
    {
        "question": "באיזה מגדל בחרת?",
        "answers": [
            `מגדל בבנייה, יפה, יוקרתי וגבוה (20 קומות+)`, 
            `מגדל בבנייה, לא גבוה ולא יוקרתי (עד 20 קומות)`, 
            `מגדל בנוי חדש, גבוה ויוקרתי`, 
            `מגדל בנוי, גבוה ויוקרתי, לא חדש (20 שנים+)`,
            `מגדל לא חדש, לא גבוה ולא יוקרתי`
        ]
    },
    // 9
    {
        "question": "מדוע ברצונך לרכוש דווקא במיקום ובפרויקט זה?",
        "answers": [
            "מכיוון שראיתי פרסום על הפרויקט, ואני חושב שהוא הכי מתאים לי", 
            "מכיוון שחבר המליץ לי", 
            "מפני שאיש השיווק של הפרויקט הסביר מדוע זאת העסקה הכי כדאית", 
            'כי התייעצתי עם יועץ נדל"ן שהמליצו לי עליו, ובדקתי משרדים דומים שמושכרים ועובדים טוב באזור הנבחר',
            "אחר..."
        ]
    },
    // 10
    {
        "question": "באיזו רמת גימור המשרד שבחרת? ",
        "answers": [
            `ברמת מעטפת (רק קירות חיצוניים וחלונות) שניתן לחלק לחלקים קטנים (עם שירותים בקומה, אפשרות לבנות מסדרון, יחס ברוטו נטו עד 27%,  עומק נכס לא יותר גדול מ-12 מ"ר ועוד)`, 
            `ברמת מעטפת גדול מ-150 מ"ר, שלא ניתן לחלקה למשרדים קטנים`, 
            `בנוי ומוכן לאכלוס (מושכר או לא מושכר) עם רמת גימור סטנדרטית`, 
            `בנוי ומוכן לאכלוס, גדול מ-150 מ"ר ועם רמת גימור גבוהה ומפוארת`,
            `בנוי ומוכן לאכלוס, קטן מ-150 מ"ר ועם רמת גימור גבוהה ומפוארת`,
            "אחר..."
        ]
    },
    // 11
    {
        "question": "מה התשואה המינימלית שאתה מוכן לקבל?",
        "answers": [
            "2 – 4 אחוזים", 
            "5 – 6 אחוזים", 
            "7 – 8 אחוזים", 
            "9 – 10 אחוזים",
            "11+ אחוזים"
        ]
    },
    // 12
    {
        "question": "באיזה גובה הנכס שברצונך לרכוש?",
        "answers": [
            "1 – 10 קומות", 
            "11 – 20 קומות", 
            "21 – 30 קומות", 
            "31+ קומות"
        ]
    },
    // 13
    {
        "question": `מה ההבדל במחיר מקומה לקומה בפרויקט?<br>(במקרה של יד2 סמן "אחר")`,
        "answers": [
            `40 – 80 ₪  למ"ר תוספת לכל קומה`, 
            `81 – 100 ₪ למ"ר תוספת לכל קומה`, 
            `101 – 200 ₪ למ"ר תוספת לכל קומה`, 
            "אחר..."
        ]
    },
    // 14
    {
        "question": "במקרה שרכשת משרד ברמת מעטפת וברצונך לבצע בניית גמר",
        "answers": [
            "אפנה לאדריכל ואעשה מה שהוא ממליץ", 
            `אפנה ליועץ נדל"ן שיודע מה שוכרים מחפשים במתחם הספציפי שבו הנני מתכוון לרכוש`, 
            "אציע חלוקה בעצמי", 
            "אפנה לחבר ואתייעץ איתו איך לחלק ואילו חומרים לבחור"
        ]
    },
    // 15
    {
        "question": `כמה ברצונך להשקיע בגמר של המשרד? <br>(במקרה ורכשת משרד עם גמר, סמן "אחר")`,
        "answers": [
            `1501 – 2000 ₪ למ"ר ברוטו`, 
            `2001 – 2500 ₪ למ"ר ברוטו`, 
            `2501 – 3500 ₪ למ"ר ברוטו`,
            `3501+ ₪ למ"ר ברוטו`,
            `אחר...`
        ]
    },
    // 16
    {
        "question": `כיצד תבחר חומרים לבנייה - ריצוף, תקרה ועוד?       <br>(במקרה ורכשת משרד עם גמר, סמן "אחר")`,
        "answers": [
            "אשאל אדריכל או מעצב פנים", 
            "אבחר לבד", 
            `אתייעץ עם יועץ נדל"ן שמשווק באזור ויודע מה בדיוק שוכרים מעדיפים`, 
            "אשאל חבר",
            "אחר..."
        ]
    },
    // 17
    {
        "question": `באיזה דרך תשווק את משרדך כדי להשכירו בהקדם ובמחיר המקסימלי האפשרי?  <br>(במקרה ורכשת משרד מושכר, סמן "אחר")`,
        "answers": [
            `אשכיר לבד כי יש לי הניסיון והידע הנדרש – בפרסום, בשיווק, ובמו"מ כמו כן עומד לרשותי הזמן הנדרש לטיפול בתהליך זה`, 
            "אבקש להיעזר בכמה מתווכים", 
            `אפנה ליועץ נדל"ן מומלץ ומצליח במתחם, ואתן לו בלעדיות כדי להעלות אצלו את המוטיבציה להצליח`, 
            "אחר..."
        ]
    },
    // 18
    {
        "question": "האם זאת ההשקעה הראשונה שלך במשרדים?",
        "answers": [
            "כן", 
            "לא"
        ]
    }
];