import React  from "react";
import { Wizards } from "./Wizards";
import { Answer } from "./Answer";
import { localStoragesKeys } from "../helper";
import { questions } from "../data/question";
import { header } from "../data/style";
import Finish from "./Finish";
import { Header } from "../header/header";
import { Button } from "../layour/button";
import moment from 'moment';

export default class Quiz extends React.Component {

    state = {
        questions: questions,
        step: -1,
        result: [],
        disableNext: false,
        height: 'auto'
    }


    componentDidMount() {
        const startTime = localStorage.getItem(localStoragesKeys.startTime) ?localStorage.getItem(localStoragesKeys.startTime) : 0;
        let stepStore = -1;
        let resultStore = [];
        if(startTime !== 0) {
            
            const startNow = moment();
            const diffStart = startNow.diff(moment(startTime).toDate(), 'minutes');

            if( diffStart <= 30 ) {
                
                stepStore = localStorage.getItem(localStoragesKeys.step) ? JSON.parse(localStorage.getItem(localStoragesKeys.step)) : -1;
                resultStore = localStorage.getItem(localStoragesKeys.result) ? JSON.parse(localStorage.getItem(localStoragesKeys.result)) : [];
            }
        }
        this.setState({step: stepStore});
        this.setState({result: resultStore});
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.step === 0 && prevState.step === -1) {
            localStorage.setItem(localStoragesKeys.startTime, moment());
        }
      }

    handleClickNext = () => {
        this.setState({height: '0px'});
        const nextStep = this.state.step + 1;
        localStorage.removeItem(localStoragesKeys.step);
        localStorage.setItem(localStoragesKeys.step, JSON.stringify(nextStep));
        this.setState({step: nextStep});
        this.setState({height: 'auto'});
    }

    handleChooseAnswer = (idx) => {
        const {result, step} = this.state;
        result[step]=idx;
        localStorage.removeItem(localStoragesKeys.result);
        localStorage.setItem(localStoragesKeys.result, JSON.stringify(result)); 
        this.setState({result});
    }

    handleClickPrev = () => {
        const prevStep = this.state.step - 1;
        localStorage.removeItem(localStoragesKeys.step);
        localStorage.setItem(localStoragesKeys.step, JSON.stringify(prevStep));
        this.setState({step: prevStep});
    }

    handlerClearStep = () => {
        this.setState({step: -1});
    }

    handlerClearAll = () => {
        localStorage.removeItem(localStoragesKeys.step);
        localStorage.removeItem(localStoragesKeys.startTime);
        localStorage.removeItem(localStoragesKeys.result);
        this.setState({result: []});
    }

    renderBody = () => {
        const { step, questions, result } = this.state;

        if ( step < 0 ) {
            return (
                <div className="quiz-nadlan-wrapper quiz-nadlan-start"> 
                    <h2  className="quiz-nadlan-header">ברוכים הבאים ל-DEAL ANALYZER</h2>
                    <p></p>
                    ברכותיי, החלטתם להשקיע במשרדים. זאת השקעה בין הטובות שיש: תשואה מקסימלית והתעסקות מינימלית.
                    <p></p>                        
                    תיהנו מהכלי הראשון והייחודי בשוק שמאפשר להעריך כדאיות של רכישת משרד ספציפי. השימוש בכלי יכול לחסוך לכם מאות אלפי שקלים ויכול לסייע למנוע עצבים מיותרים ולשמור על הכסף ועל הבריאות.
                    <p></p>
                    ענו על השאלות וקבלו דו"ח של רמת סיכון העִסקה שברצונכם לעשות. בנוסף קבלו המלצות שיעזרו לכם לבחור את האופציה הכי מתאימה לכם.
                    <p></p>
                    <small>
                    ** השאלון והדו"ח נכתבו בלשון זכר מטעמי נוחות בלבד, אך הם מיועדים לנשים ולגברים כאחד.
                    </small>
                    <p></p>
                    <Button onClick={ this.handleClickNext } className="quiz-nadlan-button quiz-nadlan-button-green start" >
                    התחל >
                    </Button>

                </div>
                );
        }

        if (step >= questions.length) {
            return (
                <Finish handlerPrev={this.handleClickPrev} onClear={this.handlerClearStep} handlerClearAll={this.handlerClearAll} answersResult={result} />
            );
        }

        const questionCurrent = questions[step];
        const  { question, answers } = questionCurrent;
        const current = result[step] !== undefined && result[step] !== -1 ? result[step] : -1;
        
        return (
            <div className="col-12">  
                <Header/>
                <Wizards step={step} counts={questions.length} />
                <h2 style={header}  dangerouslySetInnerHTML={{__html: question}}/>
         
                    <Answer answers={answers} chooseAnswer={this.handleChooseAnswer} answerCurrent={current}/>
                <br/>
                <div className="buttons-wrapper row-fluid">
                    <div className="col-md-3 col-xs-12 ">
                        <Button onClick={ this.handleClickPrev } className="quiz-nadlan-button quiz-nadlan-button-orange" >
                            {`קודמת`}
                        </Button>
                    </div>
                    <div className="col-md-3 col-xs-12 ">
                        <Button onClick={ this.handleClickNext } className="quiz-nadlan-button quiz-nadlan-button-green"  disabled={current === -1}>
                        {`הבא`}   
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
    
    
    render() {
        const { questions } = this.state;
        
        if (!questions) return "Loading";
        
        return (
            <>
                         {this.renderBody()}
            </>
        )
       
    }

}