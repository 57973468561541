import { questions } from "../data/question";
import { answer } from "../data/answer";

const buildHtmlTitle = () => {
    return '<table style="text-align: center;" align="center"><tr  style="text-align:center;"><td class="header" style="text-align: center;" align="center"><h3 style="text-align: center;" >תודה רבה שהשתמשתם ב - Deal Analyzer שלנו<br>להלן דו"ח האנליזה וההמלצות להקטנת סיכונים ברכישת משרדים להשקעה מוצלחת יותר.</h3></td></tr></table><br><br>';
}

const buildQuestion = (answersResult, temporary=false) => {
    let resultHtml = "";
    for (let i = 0; i<answer.length; i++){
        const { answers, question } = questions[i];
        if(temporary) {
            if (answersResult[i] !== null && answersResult[i] !== undefined){
                resultHtml += `<div><strong>${i+1}. ${question}</strong><br><br>${answers[answersResult[i]]}`;  
                resultHtml += `<br/></div>`;
            }
        } else {

            if (answersResult[i] !== null && answersResult[i] !== undefined && answersResult[i] !== ""){
                if (answer[i][answersResult[i]] !== undefined && answer[i][answersResult[i]] !== null && answer[i][answersResult[i]] !== "") {
                    resultHtml += `<div>`;
                    resultHtml += answer[i][answersResult[i]];
                    resultHtml += `<br/><br/>
                    <table style="text-align: center;" align="center"><tr  style="text-align:center;"><td class="header" style="text-align: center;" align="center"><img src="https://i.ibb.co/QDJJ3DT/72x72.png" alt="72x72" border="0" width="40" height="40" /></td></tr></table><br/></div>`;
                }    
            }
        }
    }
    return resultHtml;
}

const buildFooter = () => {
   return `<table style="margin-top: 40px;"><tr><td>אומרים שלא ניתן לדעת הכול, אבל מי שלומד כל הזמן, פתוח לקבלת מידע מאחרים, ומסתמך על ניסיונם – מצליח יותר, משקיע נכון ומרוויח יותר, ואף עובד פחות ונהנה.<br/>שוק המשרדים, כמו שווקים אחרים, הוא דינמי מאוד. פשוט מדהים לגלות שמה שעבד לפני 10 שנים כבר לא נכון ולא עובד היום. אסור לבנות באיכות זולה, חובה לתכנן כל דבר באופן יעיל מאוד, חשוב לדעת לעשות מו"מ חכם, וגם לדעת כיצד לסגור חוזים. המצב אינו כפי שהיה בעבר – 60% ממנו הוא חדש לגמרי.<br/>
לסיום, אנחנו רוצים לברך אתכם על שבחרתם להשתמש בכלי שלנו. כלי זה בהחלט יסייע לכם להצליח יותר בהשקעה. אנחנו, מפעילי הכלי, נשמח תמיד לעזור ולייעץ לכם בשביל שתצליחו יותר.<br/>זה תפקידנו, ומטרתנו להיות כאן תמיד בשבילכם.<br/><br/>
לכל שאלה או עזרה בתחום, דברו אתנו: <a href="tel:036965533">03-6965533</a> <br/>
או במייל: <a href="mailto:office@recom.co.il">office@recom.co.il</a>.<br/>
או ביקרו באתר שלנו: <a href="www.recom.co.il">www.recom.co.il</a>
<br/>
אנחנו תמיד לשירותכם!
<br/>
<br/>

<img src="https://i.ibb.co/wcg4qzV/unnamed-2.jpg" />
   </td></tr></table>
   </td></tr></table>
  
   `;
}

export const buildHtmlData = (answers, name, temporary=false) => {
    return `
    <html dir="rtl">
        <head>
            <style>
                td.header {
                    vertical-align: middle;
                    text-align:center;
                    width:100%;
                }
            </style>
        </head>    
        <body>
            ${buildHtmlTitle(name)}
            ${buildQuestion(answers, temporary)}
            ${buildFooter()}
        </body>
    </html>
    `;
};