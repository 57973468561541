export const localStoragesKeys = {
    step: "step",
    result: "result",
    startTime: "startTime"
}

export const generateHebrewFromNumber = (number) => {
    switch (number) {
        case 0:
            return "א";
        case 1:
            return "ב";
        case 2:
            return "ג";
        case 3:
            return "ד";
        case 4:
            return "ה";
        case 5:
            return "ד";
        default:
            return "a";
    }
}